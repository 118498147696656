import { Checkbox } from "@mui/material";
import { assignTeamMembersAtom, enableAssignmentButtonAtom, syncTeamMembersAtom } from "Atoms/AssignmentAtoms";
import { useAtom } from "jotai";
import React, { useState } from "react";

type Args = {
    rowId: string;
    isAssign: boolean;
    plmAccess: boolean;
  };
export const AssignSyncCheckbox = ({ rowId, isAssign, plmAccess }: Args) => {
    const [assignedMembers, setAssignedMembers] = useAtom(assignTeamMembersAtom);
    const [syncMembers, setSyncMembers] = useAtom(syncTeamMembersAtom);
    const [, setEnableConfirm] = useAtom(enableAssignmentButtonAtom);
    const disabled = !isAssign && !plmAccess;

    const removeMember = (members: number[], rowId: number) => {
      const index = members.indexOf(+rowId, 0);
        if (index > -1) {
          members.splice(index, 1);
        }
    }

    const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newAssignedMembers = assignedMembers;
      const newSyncedMembers = syncMembers;

      if(event.target.checked){
        if(isAssign){
          newAssignedMembers.push(+rowId)
          if(plmAccess){
            newSyncedMembers.push(+rowId)
          }
        }
        else{
          newSyncedMembers.push(+rowId)
        }
      }
      else {
        if(isAssign){
          removeMember(newAssignedMembers, +rowId);
        }
        else{
          removeMember(newSyncedMembers, +rowId);
        }
      }
      if(isAssign){
        setAssignedMembers(newAssignedMembers)
        setSyncMembers(newSyncedMembers);
      }
      else{
        setSyncMembers(newSyncedMembers);
      }
      setEnableConfirm(assignedMembers.length > 0 || syncMembers.length > 0);
    };

    return (
        <Checkbox checked={!disabled && 
          (isAssign ? assignedMembers.includes(+rowId):
           syncMembers.includes(+rowId))} onChange={handleCheckBox} disabled={disabled} />
    );
  };