import axios, { AxiosError } from 'axios';
import Config from 'config';
import { useQuery } from 'react-query';
import { QueryParam } from 'types/QueryParam';
import { SyncStatus } from 'types/SyncStatus';
import { UserDto } from 'types/UserDto';
import { createQueryParamString } from 'utilities/RequestUtil';

export const fetchOwner = async (personId: string, userName: string, displayName: string): Promise<UserDto> => {
  const body = {
    "personId": personId,
    "name": displayName,
    "userName": userName
  };

  const res = await axios.post(`${Config.baseApiUrl}/user`, body, {
    headers: { 'Content-Type': 'application/json; charset=UTF-8' }
  });

  if (!res.status) { 
    throw new Error(`status: ${res.status}`);
  }

  const data = res.data;  
  return data;
};

export const useFetchLastSyncStatus = (functionName: string) => {
  const queryParams: QueryParam[] = [
    {
      name: 'functionName',
      value: functionName
    }  
  ];

  var queryString = createQueryParamString(queryParams);

  const query = useQuery<SyncStatus, AxiosError>(["SyncStatus", functionName], () =>
    axios
      .get(`${Config.baseApiUrl}/syncstatus/${queryString}`)
      .then((resp) => {       
        return resp.data;
      })
  );

  return query;
};