import Dialog, { DialogProps } from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle, Box, Button, DialogActions, DialogContent, DialogContentText, Divider, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useAtom } from 'jotai';
import { assignTeamMembersAtom, selectedOperationIdsAtom, openAssignmentDialogAtom, syncTeamMembersAtom, enableAssignmentButtonAtom } from 'Atoms/AssignmentAtoms';
import { useFetchTeam, useFetchTeamNames } from 'hooks/TeamsHooks';
import { AutoCompleteValues } from "types/AutoCompleteValues";
import MuiDropdown from 'components/WorkAssignmnetGrid/MuiDropDown';
import { AssignmentColumns } from './AssignmentColumns';
import { useAddAssignments } from 'hooks/AssignmentHooks';
import { Assignments } from 'types/Assignments';
import { Assignment } from 'types/Assignment';
import { useFetchsettings, useUpdateSettings } from 'hooks/SettingHooks';
import { OwnerIdAtom } from 'Atoms/AuthAtoms';
import { SettingKey } from 'constants/SettingKey';
import { Settings } from 'types/Settings';
import { SortByName } from 'components/Common/SortModel';
import { BoxStyle } from './BoxStyle';
import { TableStyle } from './AssignmentStyles';
import { Messages } from 'constants/messages';
import { WaFunctionsGetSyncOperationToDays } from 'constants/Constants';
import { useFetchConfiguration } from 'hooks/ConfigurationHooks';
import { useTranslation } from "react-i18next";

const AssignmentDialog = () => {

  const [showAssignDialog, setShowAssignDialog] = useAtom(openAssignmentDialogAtom);
  const [t, ] = useTranslation();
  const [assignedMembers, setAssignedMembers] = useAtom(assignTeamMembersAtom);
  const [syncMembers, setSyncMembers] = useAtom(syncTeamMembersAtom);
  const [selectedOperations, ] = useAtom(selectedOperationIdsAtom);
  const [enableConfirm, ] = useAtom(enableAssignmentButtonAtom);
  const [OwnerId,] = useAtom(OwnerIdAtom);

  const { data: settings } = useFetchsettings(OwnerId,SettingKey.TeamLastUsed);
  const [selectedTeam, setSelectedTeam] = useState('');

  const addAssignmentMutation = useAddAssignments(+selectedTeam);
  const {data: teamNames} = useFetchTeamNames(OwnerId);
  const {data: team } = useFetchTeam(+selectedTeam, true);
  const {data: noOfDaysConfig } = useFetchConfiguration(WaFunctionsGetSyncOperationToDays);
  const [maxWidth] = React.useState<DialogProps['maxWidth']>('lg');
  const updateSettingsMutation = useUpdateSettings();

  const values = teamNames?.map(t => {
    const value: AutoCompleteValues ={
      id: t.id.toString(),
      name: t.name,
    }
    return value;
  });

  const hasOperationsInFuture = selectedOperations.filter(o => o.isOutsideSyncWindowInFuture).length > 0;
  const noOfDaysInFuture = noOfDaysConfig?.value ? +noOfDaysConfig.value : 0;

  useEffect(() => {
    if (settings && settings.length > 0) {
      setSelectedTeam(settings[0].settingValue);
    }
  }, [settings, values]);

 const handleClose = () => {
    setAssignedMembers([]);
    setSyncMembers([]);
    setShowAssignDialog(false);
  };

  useEffect(() => {
    if (selectedTeam && settings && settings.length > 0) {
      const updatedSettings: Settings = {
        ...settings[0],
        settingValue: selectedTeam,
      };
      updateSettingsMutation.mutate(updatedSettings);
    }
  }, [selectedTeam, settings, updateSettingsMutation]);

 const handleConfirm: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    let assignments: Assignments = { Assignments: []}
    const membersToPost = team?.teamMembers.filter((member) =>
    assignedMembers.includes(member.id) || syncMembers.includes(member.id))
    selectedOperations.forEach((op) => {
      membersToPost?.forEach((member) => {
        const assignment: Assignment = {
          assign: assignedMembers.includes(member.id),
          sync: syncMembers.includes(member.id),
          operationId: +op.id,
          userId: member.id,
          teamId: +selectedTeam,
          userName: member.name,
          createdByOwnerId: OwnerId,
          deletedByOwnerId: undefined,
          id: 0
        }
        assignments.Assignments.push(assignment)
      });
    });
    addAssignmentMutation.mutate(assignments);
    handleClose();
  };

  const listItems = () => {
    return selectedOperations.map((op) => (
      <ListItem sx={{ display: t("listItemText"), marginLeft: 4 }} disableGutters>
        <ListItemText
          primary={op.functionalLocation + '-' + op.workOrderDescription}
        />
      </ListItem>
      )
    )
  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={maxWidth}
        open={showAssignDialog}
        keepMounted={false}  
        aria-labelledby="responsive-dialog-title">
        <DialogContent>
          <DialogContentText>
            <Grid item container xs={12}>
              <Grid item xs={12} md={12} marginTop={0}>
                <Typography variant="subtitle1" color={'black'} fontWeight={600}>
                  {t("AssignOperationPluralText")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={5.8} marginTop={1}>
              {
                <MuiDropdown
                  options={values ?? []}
                  label={t("SelectTeamText")}
                  onChange={setSelectedTeam}
                  selectedValue={selectedTeam}
                  required={true}
                />
              }
              </Grid>
              <Grid item xs={12} md={0.2} marginTop={1} />
              <Grid item xs={12} md={6} marginTop={1}>
                <Box sx={BoxStyle}>
                  <Typography sx={{ marginTop:1, marginLeft: 2}} variant="subtitle2" color={'black'} fontWeight={600}>
                    Operation Details
                  </Typography>
                  <Divider variant="middle" />
                  <List sx={{ listStyleType: 'disc'}} dense={true}>
                    {listItems()}
                  </List>
                </Box>
              </Grid>
              <Grid item xs={12} md={12} marginTop={2}>
              {hasOperationsInFuture && (
                  <Alert severity="warning">
                    <AlertTitle>
                      {Messages.OperationInFutureAlertTitle}
                    </AlertTitle>
                      {noOfDaysInFuture < 1? Messages.OperationInFutureAlertMessage:
                        Messages.OperationInFutureAlertMessageWithDaysPrefix + noOfDaysConfig?.value + Messages.OperationInFutureAlertMessageWithDaysSuffix}
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12} md={12} marginTop={2} style={{ height: '40vh' }}>
                <DataGridPro
                  sx={TableStyle}
                  disableColumnMenu
                  hideFooter
                  rows={team?.teamMembers.filter(m => m.isActive) ?? []}
                  columns={AssignmentColumns()}
                  initialState={{
                    sorting: {
                      sortModel: [SortByName]
                    }
                  }}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            CANCEL
          </Button>
          <Button variant="contained"  disabled={!enableConfirm} color="primary" autoFocus onClick={handleConfirm}>
           {t("ConfirmCapitalisedText")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default AssignmentDialog;
