import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Button,
    Chip,
  } from "@mui/material";
  import Typography from "@mui/material/Typography";
  import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
  import { DataGridPro } from "@mui/x-data-grid-pro";
  import { TeamDetails } from "types/TeamDetails";
  import { DataTableStyle } from "components/WorkAssignmnetGrid/DataTableStyle";
  import { MembersGridColumns } from "./MembersGridColumns";
  import { openCreateTeamDialogAtom, openEditTeamDialogAtom } from "Atoms/TeamAtoms";
  import { useAtom } from "jotai";
  import React from "react";
  import { useTranslation } from "react-i18next";
  
  type TeamAccordionItemProps = {
    team: TeamDetails;
    expanded: number | false;
    handleChange: (id: number) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  };
  
  export const TeamAccordionItem = ({
    team,
    expanded,
    handleChange,
  }: TeamAccordionItemProps) => {
    const [, setShowCreateTeam] = useAtom(openCreateTeamDialogAtom);
    const [t, ] = useTranslation();
    const [, setShowEditTeam] = useAtom(openEditTeamDialogAtom);
  
    const handleEditTeamClick = () => {
      setShowEditTeam(true);
      setShowCreateTeam(false);
    };
  
    return (
      <>
        <Accordion
          square
          key={team.id.toString()}
          id={team.id.toString()}
          onChange={handleChange(team.id)}
          expanded={expanded === team.id}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography key={team.name} marginRight={1}>
              {team.name}
            </Typography>
            <Chip label={team.teamMembers.length + " Members"} size="small" />
            {team.description && 
              <Typography key={team.description} marginLeft={1} fontSize={13}>
                {"(" + team.description + ")"}
              </Typography>}
          </AccordionSummary>
          <AccordionDetails>
            <DataGridPro
              sx={DataTableStyle}
              disableColumnMenu
              hideFooter
              autoHeight
              rows={team.teamMembers}
              columns={MembersGridColumns}
            />
          </AccordionDetails>
          <AccordionActions>
            <Button onClick={handleEditTeamClick} id="btnEditTeam" key={team.id.toString()}>
              {t("EditTeamText")}
            </Button>
          </AccordionActions>
        </Accordion>
      </>
    );
  };
  
  export default TeamAccordionItem;
  