import Config from '../config';

export function mapGroupToRole(groupList: string[]): string[] {
    const roleList: string[] = [];
  
    for (const group of groupList) {
      if (Config.SupervisorGroup.includes(group)) {
        roleList.push(Config.Supervisor);
      } else {
        roleList.push('');
      }
    }
  
    return roleList;
  }