import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { AssignSyncCheckbox } from "./AssignSyncCheckbox";
import { Messages } from "constants/messages";
import { useTranslation } from "react-i18next";


export const AssignmentColumns = (): GridColDef[] => {
  const {t,} = useTranslation();
  return [
    {
      field: t("AssignSmallText"), 
      headerName: t("AssignOperationText"),
      align: 'center',
      headerAlign: 'center',
      flex: 1, 
      renderCell: (params) => {
        return (
          <AssignSyncCheckbox rowId={params.row.id} isAssign={true} plmAccess={params.row.plmAccess}/>
        );
      }
    },
    { 
      field: t("SyncSmallText"), 
      headerName: t("SyncToPLMText"),
      flex: 0.8,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <AssignSyncCheckbox rowId={params.row.id} isAssign={false} plmAccess={params.row.plmAccess}/>
        );
      }
    },
    { 
      field: t("personnelNumberText"), 
      headerName: t("PersonnelNumberText"), 
      align: 'center',
      headerAlign: 'center', 
      flex: 1 
    },
    { 
      field: t("nameSmallText"), 
      headerName: t("FullNameText"), 
      align: 'center',
      headerAlign: 'center',
      flex: 1.5 
    },
    { 
      field: t("jobTitleSamllText"), 
      headerName: t("JobTitleText"), 
      align: 'center',
      headerAlign: 'center',
      flex: 1.5 
    },
    { 
      field: t("isActiveSmallText"), 
      headerName: t("StatusText"), 
      align: 'center',
      headerAlign: 'center',
      flex: 0.8, 
      valueGetter: (params: GridValueGetterParams) => params.row.isActive ? Messages.Active : Messages.Inactive 
    },
    { 
      field: t("userTypeSmallText"), 
      headerName: t("TypeText"),
      align: 'center',
      headerAlign: 'center',
      flex: 0.8 
    },
    { 
      field: t("operationsCountSmallText"), 
      headerName: t("OperationsText"), 
      align: 'center',
      headerAlign: 'center',
      flex: 0.8 
    },
    { 
      field: t("operationsDurationSmallText"), 
      headerName: t("TotalTextDuration"), 
      align: 'center',
      headerAlign: 'center',
      flex: 1.2,
      valueGetter: (params: GridValueGetterParams) => params.row.operationsDuration.toFixed(1) + ' h'
    },
  ]
};
