import { OwnerIdAtom } from 'Atoms/AuthAtoms';
import { selectedLanguageAtom } from 'Atoms/CommonAtoms';
import { useAtom } from 'jotai';
import { useFetchsettings, useUpdateSettings, useInsertSettings } from 'hooks/SettingHooks';
import { SettingKey } from 'constants/SettingKey';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';

export const LanguageChange = (event: SelectChangeEvent<string>) => {
  const { i18n } = useTranslation();
  const [OwnerId] = useAtom(OwnerIdAtom);
  const updateLanguageSettings = useUpdateSettings();
  const insertLanguageSettings = useInsertSettings();
  const [, setSelectedLanguage] = useAtom(selectedLanguageAtom);

  const { data: LanguageSettings } = useFetchsettings(OwnerId, SettingKey.Language);

  const newLanguage = event.target.value;

  console.log(setSelectedLanguage);
  console.log(newLanguage);

  setSelectedLanguage(newLanguage);
  i18n.changeLanguage(newLanguage);

  if (LanguageSettings && LanguageSettings.length > 0) {
    const lngSetting = LanguageSettings.find((item) => item.settingKey === SettingKey.Language);

    if (!lngSetting) return;

    const updatedLanguageSettings = {
      id: lngSetting.id,
      userId: OwnerId,
      settingKey: SettingKey.Language,
      settingValue: newLanguage,
    };

    try {
      updateLanguageSettings.mutate(updatedLanguageSettings, {
        onSuccess: () => {
          console.log('Language settings updated successfully');
        },
        onError: (error) => {
          console.error('Error updating language settings:', error);
        },
      });
    } catch (error) {
      console.error('Error updating language settings:', error);
    }
  } else {
    const newLanguageSettings = [{
      userId: OwnerId,
      settingKey: SettingKey.Language,
      settingValue: newLanguage,
    }];

    try {
      insertLanguageSettings.mutate(newLanguageSettings, {
        onSuccess: () => {
          console.log('Language settings inserted successfully');
        },
        onError: (error) => {
          console.error('Error inserting language settings:', error);
        },
      });
    } catch (error) {
      console.error('Error inserting language settings:', error);
    }
  }
};

export default LanguageChange;
