import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

export const DataTableStyle: SxProps<Theme> = {
    
    overflow:'auto',
    width:'100%',   
    fontSize: '0.8em',
    fontWeight:600, 
    zIndex:0, 
    '&.detail-row .MuiDataGrid-row': {
      borderBottom: 'none'
    },
    '.MuiDataGrid-columnSeparator': {
        display: 'none',
    },
    '.MuiDataGrid-cellContent':
    {
      fontSize: '0.8em',
      textAlign:'center',
      fontWeight:400,
      zIndex:0,
      color:'black'     
    },
    borderRadius:1,  
    '& 	.MuiDataGrid-cell':{
      alignItems: 'start',
    },
    '& .static-cell:hover': {
        cursor: 'default',
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: 'rgb(199, 199, 199,0.2)',
        fontSize: '0.8em',
        fontWeight:600,
        zIndex:0,
        textAlign:'center'
    },
}