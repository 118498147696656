import ValidationSummary from "components/Common/ValidationSummary";
import { useUpdateTeam, useDeleteTeam } from "hooks/TeamsHooks";
import TeamForm from "./TeamForm";
import { editTeamIdAtom } from "Atoms/TeamAtoms";
import {  useAtomValue } from "jotai";

const EditTeam = () => {
  const teamId = useAtomValue(editTeamIdAtom);
  const updateTeamMutation = useUpdateTeam();
  const deleteTeamMutation = useDeleteTeam();
  
  return (
    <>
      {updateTeamMutation.isError && (
        <ValidationSummary error={updateTeamMutation.error} />
      )}
      <TeamForm
        teamId={teamId}
        submitted={(team) => updateTeamMutation.mutate(team)}
        deleted={() => deleteTeamMutation.mutate(teamId)}
      />
    </>
  );
};

export default EditTeam;
