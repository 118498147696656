import React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import GridRightFilters from './GridRightFilters';
import CloseIcon from '@mui/icons-material/Close';
import { RightFilterDrawerProps } from 'types/IRightFilterDrawerProps';
import { useTranslation } from "react-i18next";



const RightFilterDrawer: React.FC<RightFilterDrawerProps> = ({
  anchor,
  open,
  onClose,
  onOpen
}) => {
  const [t, ] = useTranslation();
  return (
    <SwipeableDrawer anchor={anchor} open={open} onClose={onClose} onOpen={onOpen}>
      <Box sx={{ width: 350 }} role="presentation">
        <Button variant="text" startIcon={<CloseIcon />} onClick={onClose}>
          {t("CloseText")}
        </Button>
        <GridRightFilters></GridRightFilters>        
      </Box>
    </SwipeableDrawer>
  );
};

export default RightFilterDrawer;
