import {
	Assignment as AssignmentIcon,
	Group as TeamsIcon,
	Settings as SettingsIcon,
} from '@mui/icons-material';

import type { MenuItem } from '../types/MenuItem';
import { ROUTES } from './routes';

export const MENU_LIST: MenuItem[] = [
 
	{
		route: ROUTES.workassignment,
		literal: 'Work Assignment',
		Icon: AssignmentIcon,
	},
	{
		route: ROUTES.teams,
		literal: 'Teams',
		Icon: TeamsIcon,
	},
	{
		route: ROUTES.settings,
		literal: 'Settings',
		Icon: SettingsIcon,
	},
];
