import { Box, Button, Grid, Icon, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFetchTeams } from "hooks/TeamsHooks";
import CreateTeam from "./CreateTeam";
import TeamAccordionItem from "./TeamAccordionItem";
import { useAtom } from "jotai";
import { editTeamIdAtom, openCreateTeamDialogAtom,openEditTeamDialogAtom } from "Atoms/TeamAtoms";
import React from "react";
import LoadingOverlay from "components/Common/LoadingOverlay";
import { OwnerIdAtom } from "Atoms/AuthAtoms";
import EditTeam from "./EditTeam";
import { Messages } from "constants/messages";
import { useTranslation } from "react-i18next";

export const TeamList = () => {
    const [OwnerId,] = useAtom(OwnerIdAtom);
    const {data: teams, isLoading: isTeamsLoading} = useFetchTeams(OwnerId);
    const theme = useTheme();
    const [t, ] = useTranslation();
    const sizeLessThanSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [teamId, setTeamId] = useAtom(editTeamIdAtom);
    const [expanded, setExpanded] = React.useState<number | false>(false);

    React.useEffect(() => {
        if (teams && teams.length > 0) {
            const maxTeamId = Math.max(...teams.map((team) => team.id));
            setExpanded(maxTeamId);
            setTeamId(maxTeamId);
            setShowEditTeam(false);
        }
    }, [teams]);
  
    const handleChange = (id: number) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? id : false);
      setTeamId(id);
    };
  
    const rows = teams?.map((team) => (
      <TeamAccordionItem handleChange={handleChange} expanded={expanded} key={team.id.toString()} team={team} />
    ));
  
    const totalUsers = teams?.reduce((count, current) => count + current.memberCount, 0);
    const [showCreateTeam, setShowCreateTeam] = useAtom(openCreateTeamDialogAtom);
    const [showEditTeam, setShowEditTeam] = useAtom(openEditTeamDialogAtom);
  
    const rowsWithLoader = (
      <LoadingOverlay isLoading={isTeamsLoading}>
        {showEditTeam && !showCreateTeam && <EditTeam />}
        {rows}
      </LoadingOverlay>
    );
  
    const handleCreateTeamOpen = () => {
      setShowCreateTeam(true);
      setShowEditTeam(false);
    };
  
    return (
        <>
        <Grid container spacing={2} style={{backgroundColor: 'transparent'}}>
            <Grid item container xs={12} md={9}>
                <Grid item xs={12}>
                    <Box sx={{padding: 2}}>
                        <Grid item container xs={12} rowSpacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <Typography variant="subtitle1" fontWeight={600}>{t("TeamsOverviewText")}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Box display="flex" justifyContent={sizeLessThanSmall? "flex-start": "flex-end"}>
                                    <Button variant="contained" color="primary"  onClick={handleCreateTeamOpen}>{t("CreateTeamText")}
                                    </Button>
                                </Box>
                            </Grid>
                            {teams && teams?.length < 1 &&
                              (<Grid item xs={12} md={12}>
                                  <Box display="flex" justifyContent="center" sx={{background: 'white'}}>
                                    <Typography variant="body1" marginTop={3} marginBottom={3} fontWeight={400}>{Messages.CreateTeamMessage}</Typography>
                                  </Box>
                              </Grid>)
                            }
                            {teams && teams.length > 0 && <Grid item xs={12} md={12} sx={{ minHeight: '60vh', maxHeight: '85vh', overflow: 'auto'}}>
                                {rowsWithLoader}
                            </Grid>}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

            <Grid item container xs={12} md={3} spacing={2} pr={{md:1}} justifyContent='center'>
                <Grid item xs={12}>

                    <Paper elevation={0} sx={{padding: 2}}>
                        <Stack direction="row" justifyContent="space-between">
                            <Icon sx={{ color: '#616161', minWidth: '20%' }}>group</Icon>
                            <Typography sx={{ width: '70%' }} align="left">{t("TotalTeamsText")}</Typography>
                            <Typography sx={{ width: '10%' }} align="right">{teams?.length}</Typography>
                        </Stack>
                    </Paper>
                    <Paper elevation={0} sx={{padding: 2, marginTop: 1}}>
                        <Stack direction="row">
                            <Icon sx={{ color: '#616161', minWidth: '20%'}}>account_circle</Icon>
                            <Typography sx={{ width: '70%' }} align="left">{t("NumberOfPeopleText")}</Typography>
                            <Typography sx={{ width: '10%' }} align="right">{totalUsers}</Typography>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
        {showCreateTeam && !showEditTeam && <CreateTeam />}
        </>
    );
}

export default TeamList;
