import styled from "@emotion/styled";
import { FormLabel } from "@mui/material";

export const WrapperDiv = styled.div`
  border: 1px solid rgba(224, 224, 224, 1);
  padding: 5px;
  position: relative;
  border-radius: 5px;
  height: 70px;
  overflow-x: auto; 
  max-width: 100%;
  font-weight: 500;
  font-size: 0.75em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  hyphens: auto;
`;


export const FormLabelTitle = styled(FormLabel)`
  margin-left: 0.71em;
  margin-top: -0.71em;
  padding-left: 0.44em;
  z-index: 2;
  background-color: #fff;
  position: absolute;
  font-size: 0.75em;
`;

