import { atom } from "jotai";
import { UserDto } from "types/UserDto";

export const DisplayNameAtom = atom("");
export const PersonIdAtom = atom("");
export const UserNameAtom = atom("");
export const UserAtom = atom<UserDto>({
    id: 0,
    personnelNumber: '',
    personId: '',
    jobTitle: '',
    name: '',
    username: '',
    userType: '',
    plmAccess: false,
    isActive: false,
    operationsCount: 0,
    operationsDuration: 0,
    hasSyncedOperations: false,
    companyCode: '',
});
export const OwnerIdAtom = atom(0);
export const userGroupRoleAtom = atom<string[]>([]);
