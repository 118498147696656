import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FlagIcon from '@mui/icons-material/Flag';
import RectangleIcon from '@mui/icons-material/Rectangle';
import { Chip } from '@mui/material';
import { SystemStatusConst } from 'constants/SystemStatusConst';
import React from 'react';

type SystemStatusProps = {
  status: string;
};

const SystemStatus: React.FC<SystemStatusProps> = ({ status }: SystemStatusProps) => {
  let icon: React.ReactNode;

  if (status===SystemStatusConst.Completed) {   
    icon = <CheckCircleIcon sx={{fontSize:'14px'}}/>;
  } else if (status === SystemStatusConst.Started) {    
    icon = <FlagIcon sx={{fontSize:'14px'}}/>;
  } else if (status===SystemStatusConst.Notstarted ) {    
    icon = <RectangleIcon  sx={{fontSize:'10px'}}/>;
  } else {   
    icon = <RectangleIcon sx={{fontSize:'14px'}}/>;
  }

  return (
    <Chip
      label={status}
      icon={icon}     
      variant="outlined"
      style={{fontSize:'11px',fontWeight:400,color:'black' }}
    />
  );
};

export default SystemStatus;
