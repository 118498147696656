import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

export const MembersGridColumns: GridColDef[] = [
  { field: 'id', headerName: '', width: 50, flex: 0.2, renderCell:(index) => index.api.getRowIndex(index.row.id) + 1, sortable: false},
  { field: 'personnelNumber', headerName: 'Personnel Number', flex: 1 },
  { field: 'name', headerName: 'Full Name', flex: 1 },
  { field: 'jobTitle', headerName: 'Job Title', flex: 1 },
  { field: 'isActive', headerName: 'Status', flex: 1,  valueGetter: (params: GridValueGetterParams) => params.row.isActive ? 'Active' : 'Inactive' },
  { field: 'userType', headerName: 'Type', flex: 1 },
  { field: 'plmAccess', headerName: 'PLM Access', flex: 1, valueGetter: (params: GridValueGetterParams) => params.row.plmAccess ? 'Yes' : 'No'},
];
