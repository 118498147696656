import React, { useState, useRef } from "react";
import { Autocomplete, AutocompleteChangeReason, Avatar, Box, Grid, TextField, Typography } from "@mui/material";
import { TeamMember } from "../../types/TeamMember";
import { useFetchTeamMember } from "hooks/TeamsHooks";
import { Messages } from "constants/messages";

interface Props {
  data: TeamMember[];
  disable: boolean;
  setData: (data: TeamMember[]) => void;
}

const TeamMemberSearch: React.FC<Props> = ({ data, setData, disable }) => {
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<TeamMember | null>(null);
  const autocompleteRef = useRef<any>(null); // Ref for the Autocomplete component

  const handleInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
    setInputValue(value);
  };

  const { data: options, isLoading } = useFetchTeamMember(inputValue);

  const handleSelect = (
    event: React.SyntheticEvent,
    value: TeamMember | null,
    reason: AutocompleteChangeReason
  ) => {
    const newData = value && !data.some((d) => d.personnelNumber === value.personnelNumber) ? [value] : [];
    setData([...data, ...newData]);

    setInputValue("");
    setOpen(false);
    if (autocompleteRef.current) {  
      autocompleteRef.current.blur(); // Clear the Autocomplete input field
    }
  };

  return (
    <Autocomplete
      disabled={disable}
      ref={autocompleteRef} // Assign the ref to the Autocomplete component
      options={!options ? [] : options}
      clearOnBlur={true}
      freeSolo={false}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={isLoading}
      getOptionLabel={(option) => `${option.name}, ${option.jobTitle}, ${option.personnelNumber}`}
      onChange={handleSelect}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      value={selectedValue}
      renderOption={(props, option) => {
        return (
          <li {...props} key={`${option.personnelNumber}`}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <Avatar sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                <Box key={option.personnelNumber} component="span">
                  {option.name}
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {option.jobTitle}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {option.personnelNumber}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      sx={{ width: 320 }}
      renderInput={(params) => (
        <TextField {...params} label={Messages.SearchUserPlaceholder as React.ReactNode} variant="outlined"
        InputLabelProps={{ children: Messages.SearchUserPlaceholder as React.ReactNode }}/>
      )}
    />
  );
};

export default TeamMemberSearch;
