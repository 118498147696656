import { dateLimit } from 'utilities/DatesFunction';
export const enum Messages {
    DeleteTeamWithAssignments = "Warning - Team members in this team are currently assigned to work",
    DeleteTeamWithoutAssignments = "Warning - This action cannot be undone",
    DeleteTeamWarning = "Deleting a team cannot be undone. Once you have clicked save, the team will be deleted.",
    DeletedTeamMembersWarning = "Team member(s) you have selected are currently assigned to work and deleting them will remove them from all assigned operations. This change will take effect when you click the 'SAVE' button",
    DeletedTeamMemberWarning = " is currently assigned to operation(s) and deleting them will remove them from all assigned operation(s). This change will take effect when you click the 'SAVE' button.",
    SearchUserPlaceholder = "Search by name or personnel number",
    TeamMemberPlmAccessMessage = "Each team must have at least one team member with PLM access.",
    OperationInFutureAlertTitle = "Warning. Operation is outside of sync window",
    OperationInFutureAlertMessage = "The selected operation(s) will not sync on the PLM tablet until the operation's scheduled date.",
    OperationInFutureAlertMessageWithDaysPrefix = "The selected operation(s) will not sync on the PLM tablet until ",
    OperationInFutureAlertMessageWithDaysSuffix = " day(s) before the operation's scheduled date.",
    DisabledOperationTooltipTitle = "Operation Earliest Start date is outside sync window. Adjust date in SAP to assign in Work Assignment.",
    DeleteTeamCheckboxWarning = "Ticking this box will confirm that you are happy for ",
    TeamToBeDeletedMessage = " Team to be deleted",
    SettingsSucess = "Your settings saved successfully!",
    Undo = "Undo",
    Active = "Active",
    Inactive = "Inactive",
    PlanningPlantReq="Planning Plant(s) is required",
    WorkCenterReq="Work Center(s) is required",
    StartDateReq="Scheduled start from",
    EndDateReq="Scheduled start to",
    NoOperationsFoundTextForPlant = "No search results for your criteria.",
    Save = "SAVE",
    Create = "CREATE",
    CreateTeamLabel = "Create a New Team",
    CreateTeamMessage = "Create a team by adding at least one team member with PLM access.",
    EditTeamLabel = "Edit ",
    TeamNameLabel = "Team Name",
    FortyCharacters = "/40 characters",
    HundredTwentyCharacters = "/120 characters",
    Description = "Description"   
  }
  export const NoOperationsFoundTextForWO = () => `No search results found from ${dateLimit.min.format("DD/MM/YYYY")} to ${dateLimit.max.format("DD/MM/YYYY")}`;
  export const syncErrorMessageFunc = (list: string[], nextSync: string) => {
    let title = '';
    let description = `Operation data might not be up to date. Next sync starts at ${nextSync}`;  
    if (list.length <= 3 && list.length > 0) {
      const formattedList = list.join(', ');
      title = formattedList + " sync issue";     
    } else if (list.length > 3) {
      title = 'Plant sync issue';
      description = `Operation data might not be up to date. Next sync starts at ${nextSync}`;
    }
    else { 
      title = 'Plant sync issue';
      description = `Operation data might not be up to date. Next sync starts at ${nextSync}`;
    }
  
    return { title, description };
  };
  