import React from "react";
import { Container, Link } from "@mui/material";
import Header from "components/Header";
import { ReactComponent as Logo } from "../../assets/RioTintoLogo.svg";
import { Project } from "constants/project";
import { useTranslation } from "react-i18next";


const UnAuthorizedAccess = () => {
  const [t, ] = useTranslation();
   return(
    <>
    <Header />

    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Container maxWidth="sm" style={{ textAlign: 'center' }}>
          <Logo style={{ width: '7vw' }} />
          <h3>You don't have access to this application.</h3>
          <p>
            To get access to this application, please email <span style={{ fontWeight: "bolder" }}>Paperless Maintainer Support</span>{" "}
            <Link underline="always" href={`mailto:${Project.SupportEmail}`} target="_top">here</Link>{" "}
            or{" "}
            <Link underline="always" href={Project.ServiceNowUrl} target="_blank" rel="noopener">submit a ticket to ServiceNow.</Link>
          </p>
        </Container>
      </div>
    </div>
  </>
);
}

export default UnAuthorizedAccess;
