import { IAlertType } from "types/IAlertType";

export const AlertDescriptions: { [key: string]: IAlertType } = {
  'CriticalAsset': {   
    description: 'Critical Asset',
  },
  'FunctionalSafety': {   
    description: 'Functional Safety',
  },
  'HasDigitalChecklist': {   
    description: 'Digital Checklist',
  },
  'HasOpenDelays': {  
    description: 'Work Delay',
  },
  'HasParts': {   
    description: 'Part',
  },
  'HasPrt': {  
    description: 'PRT',
  },
  'HasReturnParts': {
    description: 'Return Part',
  },
  'ProcessSafety': {
    description: 'Process Safety',
  },
  'Statutory': {    
    description: 'Statutory Work',
  },
  'UnderWarranty': {  
    description: 'Check Warranty',
  },
};
