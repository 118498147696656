import { Box } from "@mui/material";
import { TabPanelProps } from "types/TabPanelProps";

export const CustomTabPanel=(props: TabPanelProps)=>
 {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"       
        hidden={value !== index}
        id={`tabpanel-${index}`}       
        {...other}
      >
        {value === index && (
          <Box sx={{paddingTop:3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

