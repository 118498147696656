import { GridColDef, GridRenderCellParams, GridRowId, GridValueGetterParams } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from "@mui/material";
import { Messages } from "constants/messages";

interface Props {
  onDelete: (id: GridRowId) => void;
  onUndo: (idd: GridRowId) => void;
  isDeleted: (id: GridRowId) => boolean;
}
export const SearchResultColumn = ({ onDelete, onUndo, isDeleted }: Props): GridColDef[] => [
 
  { field: 'id', headerName: '', flex: 0.2, renderCell:(index) => index.api.getRowIndex(index.row.id) + 1},
  { field: 'personnelNumber', headerName: 'Personnel Number', flex: 0.8},
  { field: 'name', headerName: 'Full Name', flex: 1.2},
  { field: 'jobTitle', headerName: 'Job Title', flex: 1.2},
  { field: 'isActive', headerName: 'Status', flex: 0.6, valueGetter: (params: GridValueGetterParams) =>
    params.row.isActive ? 'Active' : 'Inactive'
  },
  { field: 'userType', headerName: 'Type', flex: 0.6},
  { field: 'PLMAccess', headerName: 'PLM Access', flex: 0.8, valueGetter: (params: GridValueGetterParams) =>
    params.row.plmAccess ? 'Yes' : 'No'
  },
  {
    field: 'deleteButton',
    headerName: '',
    filterable: false,
    width: 80,    
    renderCell: (params: GridRenderCellParams) => {
      const handleDeleteClick = () => {
        if (onDelete) {
          onDelete(params.id as GridRowId);
        }
      };
      const handleUndoClick = () => {
        onUndo(params.id as GridRowId);
      };

      return isDeleted(params.id as GridRowId) ?
      (
        <Link
          onClick={handleUndoClick}
        >
          {Messages.Undo}
        </Link>
      ): 
      (
        <DeleteIcon color="action" onClick={handleDeleteClick} />
      );
    }
  }
];
