import { SxProps, Theme } from "@mui/material";

// SxProps will not work for Box and Typography component.

export const BoxStyle = {
    backgroundColor: '#0000000F',
    marginTop:1,
    marginLeft: 5,
    padding:0.5,
    fontSize: '0.8em',
    maxHeight:'70vh',
    height:'70vh',
    overflow:'auto',
    color:'#00000099',
    fontWeight:400,
    borderRadius:'10px'
  
  };
  
  export const GridItemStyle: SxProps<Theme> = {
    margin: 2,
    lineBreak:'auto'
  };