import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import {
  Autocomplete,
  TextField,
  Typography,
  Checkbox,
  AutocompleteChangeReason,
  Chip
} from "@mui/material";
import { AutoCompleteValues } from "types/AutoCompleteValues";
import { MultiSelectDropdownProps } from "types/MultiSelectDropDownProps";

const StyledFormControl = styled(FormControl)`
  margin: 1;
  width: 100%;
`;

const StyledChip = styled(Chip)`
  && {
    font-size: 13px;
    white-space: nowrap;     
  }
`;

const MultiSelectDropdown = ({
  options,
  label,
  onChange,
  selectedValues,
  required,
  width = "100%",
  marginLeft = 0,
  marginBottom = 0,
  concateText = false,
  renderCode = false,
  isLoading = false
}: MultiSelectDropdownProps) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [open, setOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleChange = (
    event: React.SyntheticEvent,
    values: AutoCompleteValues[],
    reason: AutocompleteChangeReason
  ) => {
    onChange(values.map((val) => val.id));
    setFilteredOptions(options);
  };
  
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let updatedOptions: AutoCompleteValues[];
    const inputValue = event.target.value;
    if (renderCode) {
      updatedOptions = options.filter((option) => {
        return option.code?.toLowerCase().includes(inputValue.toLowerCase());
      });
    } else {
      updatedOptions = options.filter((option) => {
        return (
          option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
          option.id.toLowerCase().includes(inputValue.toLowerCase())
        );
      });
    }  
    setFilteredOptions(updatedOptions);
  };

  return (
    <StyledFormControl required={required} error={required && !selectedValues.length}>
      <Autocomplete
        multiple       
        disableCloseOnSelect
        size="small"
        limitTags={2}
        options={filteredOptions}
        value={selectedValues}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        loading={isLoading}      
        getOptionLabel={(option) => concateText? (renderCode? option.code: option.id) + "-" + option.name : option.name}     
        onChange={handleChange}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <StyledChip
              variant="filled"
              label={concateText? (renderCode? option.code: option.id) + "-" + option.name : option.name}
              // apply custom styles
              {...getTagProps({ index })}
            />
          ))
        }
        sx={{ width: width, marginLeft: marginLeft, marginBottom: marginBottom }}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={`${option.id}`}>
            <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 1 }}
                checked={selected}
              />
            {concateText? (renderCode? option.code: option.id) + "-" + option.name : option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField           
            {...params}
            size="small"
            label={label}        
            variant="outlined"
            error={required && !selectedValues.length}
            InputLabelProps={{
              style: { fontSize: 12 }, // adjust the font size as needed
            }}
            onChange={handleInputChange}
          />
        )}
      />
      {required && !selectedValues.length && (
        <Typography
          fontSize={10}
          fontWeight={400}
          color="red"
          marginLeft={2}
          variant="subtitle2"
        >
          {label} is required
        </Typography>
      )}
    </StyledFormControl>
  );
};

export default MultiSelectDropdown;
