import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

export const AutoCompleteStyle: SxProps<Theme> = {

  ".MuiFormControl-root":{
      zIndex: `1000 !important`,
      background:'white',

  },
  ".MuiPopover-paper": {
      background:'white',

    },
  ".MuiOutlinedInput-root": {
      paddingRight:0    
  },
  ".MuiAutocomplete-endAdornment": {
    right:'9px',
    top:'3px'
  },
  ".MuiAutocomplete-tag": {
    maxWidth: 'calc(100% - 50px)'
  },
  ".MuiAutocomplete-input": {
    minWidth:'0px!important',
  },
}
