import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import translationEnglish from "./locales/en/translation.json"

const resources ={
    en: {
        translation: translationEnglish,
    }
}

i18next
.use(initReactI18next)
.init({
    lng:'en',
    resources,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
});
