import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BuildIcon from '@mui/icons-material/Build';
import { Chip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Assignment } from "types/Assignment";
import { CustomChipStyle, FormLabelTitle, WrapperDiv } from './CustomChipStyles';

interface CustomChipProps {
  label: string;
  values: Assignment[];
  onDeleteChip?: (chipToDelete: Assignment) => void; // new prop
}

const getChipIcon = (chip: Assignment) => {
  if (chip.assign && chip.sync) {
    return (
      <div>
        <BuildIcon sx={{ fontSize: '0.7em' }} />
        <AssignmentIndIcon sx={{ fontSize: '0.7em', marginLeft: 1 }}/>
      </div>
    );
  } else if (chip.assign) {
    return  (
      <div><BuildIcon sx={{ fontSize: '0.7em' }}/></div>
    )
  } else if (chip.sync) {
    return  (
      <div>
        <AssignmentIndIcon sx={{ fontSize: '0.7em' }}/>
      </div>
    )
  }
};

const CustomChip: React.FC<CustomChipProps> = ({ label, values, onDeleteChip }) => {
  const [chips, setChips] = useState<Assignment[]>(values);

  const handleDelete = (chipToDelete: Assignment) => () => {
    setChips(prevChips => prevChips.filter(chip => chip.id !== chipToDelete.id));
    if (onDeleteChip) {
      onDeleteChip(chipToDelete);
    }
  };

  useEffect(() => {
    setChips(values);
  }, [values]);

  return (
    <>
      <FormLabelTitle>{label}</FormLabelTitle>
      <WrapperDiv>
        {chips.map((chip, index) => (
          <Chip
            size="small"
            sx={CustomChipStyle} 
            variant={onDeleteChip ? 'outlined' : 'filled'}       
            key={index}
            label={chip.userName}
            onDelete={onDeleteChip ? handleDelete(chip) : undefined}
            icon={getChipIcon(chip)}
          />
        ))}
      </WrapperDiv>
    </>
  );
};

export default CustomChip;
