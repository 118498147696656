import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { Tooltip } from "@mui/material";

type Args = {
  props: JSX.IntrinsicAttributes & CheckboxProps;
  tooltipTitle: string;
};
function CheckboxWrapper({props, tooltipTitle}: Args) {

  return (
    <Tooltip title={tooltipTitle} placement="top" arrow>
      <span>
        <Checkbox
          {...props}
        />
      </span>
    </Tooltip>
  );
}

export default CheckboxWrapper;
