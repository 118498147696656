export const SyncOperationConst = 'SyncOperations';
export const SyncFail = 'Fail';
export const WaFunctionsGetSyncOperationToDays = 'WaFunctions:GetSyncOperationToDays';
export const SelectOption = 'selectOption';
export const RemoveOption = 'removeOption';
export const Clear = 'clear';
export const SelectAllId = 'select-all';
export const SelectAll = 'Select All';
export const SyncTime = '30';

export const StylingConstants = {
    marginRight: 2,
    marginBottom: 2,
    minWidth: '320px',
    maxWidth: '320px',
  };
  
