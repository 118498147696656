import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

export const BoxStyle: SxProps<Theme> = {
    bgcolor: 'background.paper',
    border: 1,
    borderColor: '#0000003B',
    borderRadius: 1,
    fontSize: '0.8em',
    height: '110px',
    overflowY: 'auto',
    overflowX: 'hidden'
  }