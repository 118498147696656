import { AxiosError } from "axios";
import Problem from "../../types/problem";
import { useTranslation } from "react-i18next";

type Args = {
  error: AxiosError<Problem>;
};

const ValidationSummary = ({ error }: Args) => {
  const [t, ] = useTranslation();
  if (error.response?.status !== 400) return <></>;
  const errors = error.response?.data.errors;
  return (
    <>
      <div className="text-danger">{t("PleaseFixTheFollowingText")}</div>
      {Object.entries(errors).map(([key, value]) => (
        <ul key={key} id="ValidationSummary">
          <li id={key}>
            {key}: {value.join(", ")}
          </li>
        </ul>
      ))}
    </>
  );
};

export default ValidationSummary;
