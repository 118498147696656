import { Box, CircularProgress } from "@mui/material";

type Props = {
  isLoading: boolean;
  children: React.ReactNode;
};

const LoadingOverlay = ({ isLoading, children }: Props) => {
  return isLoading ? (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
      {children}
    </Box>
  ) : (
    <>{children}</>
  );
};

export default LoadingOverlay;
