import { Link } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { MenuItem } from '../types/MenuItem';
import { useAtom } from 'jotai';
import { drawerAtom } from 'Atoms/WorkAssignmentGridAtoms';

type Props = MenuItem & {
  selected?: boolean;
  onClick?: () => void;
};

export const MenuItems: React.FC<Props> = ({
  route,
  literal,
  Icon,
  selected,
  onClick,
}) => {
  const [isOpened,] = useAtom(drawerAtom);
  const link = (
    <ListItem
      selected={selected}
      sx={{
        '&.Mui-selected': {
          backgroundColor: '#DBEEFF',
          color: '#262B30',
        }     
      }}
      title={literal}
      onClick={onClick}
    >
      <ListItemIcon
        sx={[
          { minWidth: 'auto' },
          (theme) => ({
            paddingRight: theme.spacing(2),
          }),
        ]}
      >
        <Icon sx={{ color: selected ? '#005BD1' : '#616161' }} />
      </ListItemIcon>
      <ListItemText primary={literal}/> 
  </ListItem>
  );

  return route ? <Link to={route}>{link}</Link> : link;
};
