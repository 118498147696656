import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { DisplayNameAtom } from 'Atoms/AuthAtoms';
import { Project } from 'constants/project';
import { useAtom } from 'jotai';
import { LanguageChange } from '../utilities/LanguageChange'
import { ReactComponent as Logo } from '../assets/RioTintoLogo.svg';
import { useTranslation } from 'react-i18next';
import { useFetchsettings } from 'hooks/SettingHooks';
import { OwnerIdAtom } from 'Atoms/AuthAtoms';
import { SettingKey } from 'constants/SettingKey';

export const Header = () => {
  const { t, i18n } = useTranslation();
  const [DisplayName] = useAtom(DisplayNameAtom);
  const [OwnerId] = useAtom(OwnerIdAtom);
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const {
    data: LanguageSettings,
    isLoading: isLanguageSettingsLoading,
  } = useFetchsettings(OwnerId, SettingKey.Language);

  useEffect(() => {
    if (!isLanguageSettingsLoading) {
      if (LanguageSettings && LanguageSettings.length > 0) {
        setSelectedLanguage(LanguageSettings[0].settingValue);
        i18n.changeLanguage(LanguageSettings[0].settingValue);
      } else {
        setSelectedLanguage('en');
        i18n.changeLanguage('en');
      }
    }
  }, [LanguageSettings, isLanguageSettingsLoading, i18n]);

  const handleLanguageChange = LanguageChange;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar sx={{ backgroundColor: 'black', color: 'secondary.contrastText' }}>
        <Toolbar sx={{ flexGrow: 1 }}>
          <Logo style={{ width: '7vw' }} />
          <Typography variant="subtitle2" sx={{ wordWrap: 'break-word', width: 120, marginLeft: 5 }} align="left">
            {Project.Name}
          </Typography>
          <Typography sx={{ flexGrow: 1 }} align="right">
            <Select
              label="Language"
              value={selectedLanguage}
              onChange={handleLanguageChange}
              sx={{ color: 'white', marginRight: '30px' }}
              IconComponent={() => <IconButton disableRipple><ArrowDropDown sx={{ color: 'white' }} /></IconButton>}
            >
              <MenuItem value="en">ENGLISH</MenuItem>
              {/* Add more languages as needed */}
            </Select>
            {t('WelcomeText')}, {DisplayName}
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
