import styled from "@emotion/styled";
import { FormLabel } from "@mui/material";

export const WrapperDiv = styled.div`
  border: 1px solid rgba(224, 224, 224, 1);
  padding: 1px;
  position: relative;
  border-radius: 5px;
  height: 70px;
  overflow: auto;
`;

export const FormLabelTitle = styled(FormLabel)`
  margin-left: 0.71em;
  margin-top: -0.71em;
  padding-left: 0.44em;
  z-index: 2;
  background-color: #fff;
  position: absolute;
  font-size: 0.75em;
`;

export const CustomChipStyle = {
    fontSize: '0.7em',
    marginTop: '7px',
    marginLeft: '2px',
    BorderColor:'#000'
  };