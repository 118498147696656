import { QueryParam } from "types/QueryParam";

export function createQueryParamString(params: QueryParam[]): string {
  const queryParams = params.map((param) => {
    if (Array.isArray(param.value)) {
      return param.value.map((value) => `${param.name}=${encodeURIComponent(value)}`).join('&');
    } else {
      return `${param.name}=${encodeURIComponent(param.value)}`;
    }
  }).join('&');

  return `?${queryParams}`;
}