import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from '@mui/material';
import "./index.css";
import "./i18n"
import App from "./App";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";
import { MsalProvider } from "@azure/msal-react";

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <CssBaseline />
    <BrowserRouter>
    <MsalProvider instance={msalInstance}>
          <App />
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>
);
