import { AutoCompleteValues } from "types/AutoCompleteValues";

export const extractUniqueValues = <T extends Record<string, any>>(
  workAssignments: T[],
  key: keyof T
) => {
  const uniqueValues = new Set<string>();
  workAssignments.forEach((item) => {
    if (item !== null && item !== undefined && item[key] !== null && item[key] !== undefined && item[key]!=="") {
      const value = item[key];
      uniqueValues.add(value.toString());
    }
  });
  return Array.from(uniqueValues).map((value) => ({ name: value, id: value }));
};

export const extractUniqueValuesUsingSecondKey = <T extends Record<string, any>, K extends keyof T>(
  workAssignments: T[],
  firstKey: K,
  secondKey: string
) => {
  const uniqueValues = new Set<string>();
  workAssignments.forEach((item) => {
    if (item !== null && item !== undefined && item[firstKey] !== null && item[firstKey] !== undefined && item[firstKey] !== "") {
      const firstValue = item[firstKey];
      if (Array.isArray(firstValue)) {
        firstValue.forEach((element: any) => {
          if (element[secondKey] !== null && element[secondKey] !== undefined && element[secondKey] !== "") {
            const secondValue = element[secondKey];
            uniqueValues.add(secondValue.toString());
          }
        });
      } else if (firstValue[secondKey] !== null && firstValue[secondKey] !== undefined && firstValue[secondKey] !== "") {
        const secondValue = firstValue[secondKey];
        uniqueValues.add(secondValue.toString());
      }
    }
  });
  return Array.from(uniqueValues).map((value) => ({ name: value, id: value })).sort((a, b) => a.name.localeCompare(b.name));
};

export const enrichedUniqueValues = (
  values: AutoCompleteValues[],
  details: AutoCompleteValues[]
) => {
  const ids = values.map(v => v.id);
  const detailedValues = details.filter(v => ids.includes(v.id));
  if(detailedValues){
    return detailedValues;
  }
  return values;
};

export const enrichedUniqueValuesUsingCode = (
  values: AutoCompleteValues[],
  details: AutoCompleteValues[]
) => {
  const ids = values.map(v => v.id);
  const detailedValues = details.filter(v => ids.includes(v.code ?? v.id)).map(v => ({
    id: v.code ?? v.id,
    name: v.name,
    code: v.code
  }));
  if(detailedValues){
    return detailedValues;
  }
  return values;
};


