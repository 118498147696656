import { refreshGridDataAtom } from "Atoms/WorkAssignmentGridAtoms";
import axios, { AxiosError } from "axios";
import Config from "config";
import { useAtom } from "jotai";
import { useQuery } from "react-query";
import { OperationSyncLog } from "types/OperationSyncLog";
import { QueryParam } from "types/QueryParam";
import { createQueryParamString } from "utilities/RequestUtil";

const useFetchOperationLog = (syncLogParams: OperationSyncLog) => {
  const [refreshGridCount] = useAtom(refreshGridDataAtom);

  const queryParams: QueryParam[] = [
    {
      name: 'PlanningPlants',
      value: syncLogParams.planningPlants,
    },
    {
      name: 'datefrom',
      value: syncLogParams.startDateFrom,
    },
    {
      name: 'dateto',
      value: syncLogParams.startDateTo,
    },
    {
      name: 'status',
      value: syncLogParams.status,
    }
  ];

  const queryString = createQueryParamString(queryParams);

  return useQuery<string[], AxiosError>(
    ["OperationLog", refreshGridCount],
    () =>
      axios.get(`${Config.baseApiUrl}/GetOperationSyncLog${queryString}`)
        .then((resp) => resp.data),
    {
      enabled: refreshGridCount > 0,
    },
  );
};

export { useFetchOperationLog };
