import { Avatar, AvatarGroup, Link, Stack } from "@mui/material";
import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF, 
  GridColDef
} from "@mui/x-data-grid-pro";
import * as SystemAlertImages from '../../assets/SystemAlerts';
import { GridPanelToggle } from "./GridPanelToggle";
import dayjs from "dayjs";
import SystemStatus from "./SystemStatus";
import { Project } from "constants/project";
import { AlertToolTip } from "./AlertToolTip";
import { AlertDescriptions } from "constants/AlertDescriptions";
const SAPClientId = process.env.REACT_APP_SAPCLIENTID??'';
const SAPTransaction32URL = process.env.REACT_APP_SAPTRANSACTION32URL??'';
export const WorkAssignmentColumns: GridColDef[] = [
  { field: '__check__', hideable: false, align: 'center',flex:0.1,sortable:false },
  { 
    field: "id", 
    headerName: "id",
    description: "id",
    hideable:false,
    hide:true,
  },
  { 
    field: "planningPlantId", 
    headerName: "Planning Plant",     
    description: "Planning Plant",
    hide:true,
    hideable:false
  },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    hideable:false,
    renderCell: (params) => (
      <GridPanelToggle id={params.id} value={params.value} />
    ),
  },
  {
    field: "scheduledStart",
    headerName: "Schd. Start",
    description: "Scheduled Start Date",
    flex: 1,
    renderCell: (params) => {
      return (
        <div>
          <div>{dayjs(params.value).format('DD/MM/YYYY')}</div>
          <div>{dayjs(params.value).format('HH:mm')}</div>
        </div>
      );
    },     
  },
  {
    field: "workOrderId",
    headerName: "Work Order",
    description: "Work Order",
    hideable:false,
    flex: 1,
    renderCell: (params) => {
      return (
        <Stack>
          <div><Link href={SAPTransaction32URL.replace(Project.ReplacementTextForWO,params.row.workOrderId).replace(Project.ReplacementTextForSAPClient,SAPClientId)} target="_blank" rel="noopener">{params.row.workOrderId} </Link></div>
          <div><Link href={SAPTransaction32URL.replace(Project.ReplacementTextForWO,params.row.workOrderId).replace(Project.ReplacementTextForSAPClient,SAPClientId)} target="_blank" rel="noopener">{params.row.workOrderDescription}</Link></div>
        </Stack>
      );
    },
  },
  {
    field: "operationNumber",
    headerName: "Operation",
    description: "Operation",
    hideable:false,
    flex: 0.8, 
    renderCell: (params) => {
      return (
        <div>
        <div>{params.row.operationNumber}</div>
        <div>{params.row.operationDesc}</div>
      </div>
      );
    },
  },
  {
    field: "workCenter",
    headerName: "Work Center",
    description: "Work Center",
    flex: 1, 
    renderCell: (params) => {
      return (
        <div>
         <div>{params.row.workCenter}</div>
        </div>
      )
    }, 
  },
  {
    field: "productiveUnit",
    headerName: "Productive Unit",    
    description: "Productive Unit",
    flex: 1.2, 
    renderCell: (params) => {
      return (
        <div>
          <div>{params.row.productiveUnit}</div>
          <div>{params.row.productiveUnitDesc}</div>
        </div>
      );
    },
  },
  {
    field: "functionalLocation",
    headerName: "Functional Location",
    flex: 1.5, 
    renderCell: (params) => {
      return (
        <div>
        <div>{params.row.functionalLocation}</div>
        <div>{params.row.functionalLocationDesc}</div>
      </div>
      );
    },
  },
  {
    field: "teamName",
    headerName: "Team Name",
    flex: 1, 
    renderCell: (params) => {
      return (
        <div>{params.row.teamName}</div>
      );
    },
  },
  {
    field: "systemCondition",
    headerName: "Sys. Condition",
    description: "System Condition",
    flex: 1.2,
    renderCell: (params) => {
      return (
        <div>{params.row.systemCondition}</div>
      )
    }, 
  },
  {
    field: "systemStatus",
    headerName: "Sys. Status",
    description: "System Status",
    flex: 1.2, 
    renderCell: (params) => {
      return (
        <div>
          <SystemStatus status={params.row.systemStatus}></SystemStatus>
        </div>)
    },
  },
  {
    field: "requiredPeople",
    headerName: "Rqd. People",
    description: "Rqd. People",
    flex: 1.2,
    renderCell: (params) => {
      return (
        <div>
          <div>{params.row.requiredPeople}</div>
        </div>
      )
    }, 
  },
  {
    field: "duration",
    headerName: "Duration",
    description: "Duration",
    flex: 1, 
    renderCell: (params) => {
      return (
        <div>
         <div>{params.row.duration + " H"}</div>
        </div>
      )
    }, 
  },
  {
    field: "assignedUsers",
    headerName: "As. People",
    align: 'left',
    flex: 1, 
    renderCell: (params) => {
      return (     
       <div>{params.row.assignedUsers.length}</div>       
      );
    },
    sortComparator: (v1, v2) => v1.length - v2.length   
  },
  {
    field: "Alerts",
    headerName: "Alerts",
    flex: 1,
    align: 'right',
    renderCell: (params) => {
      const values = params.row.systemAlerts;
      let permitsAvatar = null;
      const alertsContent = values.map((alert: string, index: number) => {
        const imageUrl = SystemAlertImages[alert as keyof typeof SystemAlertImages];
        const alertval = AlertDescriptions[alert];
        if (alertval) {
          return (
            <div key={params.row.id + alert} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
            <Avatar alt={alert} src={imageUrl} sx={{ width: 20, height: 20, marginRight: '8px' }} />
            <span>{alertval.description}</span>
          </div>
          );
        }
        return null;
      });
      if (params.row.permitsCount > 0) {
        permitsAvatar = (
          <div key={params.row.id + "Permits"} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
          <Avatar alt="Permit(s)"  src={SystemAlertImages.Permits} sx={{ width: 20, height: 20, marginRight: '8px' }}>
           </Avatar>
            <span>Permit(s)</span>         
          </div>
        );
      }  
      return (
        <AlertToolTip title={<div>{alertsContent}{permitsAvatar}</div>}>
          <AvatarGroup  max={1} sx={{
            '& .MuiAvatar-root': { width: 30, height: 30, fontSize: 14 }          
          }}>
            {values.map((alert: string, index: number) => {
                const imageUrl = SystemAlertImages[alert as keyof typeof SystemAlertImages];
                const alertval = AlertDescriptions[alert];
              if (alertval) {
                return (
                  <Avatar key={params.row.id + alert} alt={alertval.description} src={imageUrl}/>
                );
              }
              return null;
            })}
              {params.row.permitsCount > 0 && (
                <Avatar src={SystemAlertImages.Permits} key={params.row.id + "Permits"} alt={"Permit(s)"}>
                </Avatar>
            )} 
          </AvatarGroup>
        </AlertToolTip>
      );
    },
  },
  {
    field: "plannerGroup",
    headerName: "Planner Group",  
    description: "Planner Group",
    hide:true,
    hideable:false
  },
  {
    field: "priority",
    headerName: "Priority",  
    description: "Priority",
    flex: 1, 
    hide:true,
    hideable:false
  },
  {
    field: "orderType",
    headerName: "Order Type",  
    description: "Order Type",
    flex: 1, 
    hide:true,
    hideable:false
  },
  {
    field: "revision",
    headerName: "Revision",  
    description: "Revision",
    flex: 1, 
    hide:true,
    hideable:false
  },
  {
    field: "genericField",
    headerName: "GenericField",  
    description: "GenericField",
    flex: 1, 
    hide:true,
    hideable:false
  }  
];
