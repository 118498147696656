import { Box, Toolbar, styled } from "@mui/material";
import { ReactNode } from "react";

import { Drawer } from "../components/Drawer";
import { Header } from "../components/Header";
import { Main } from "../components/Main";

const OuterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InnerContainer = styled(Box)`
  display: flex;
  flex: 1;
  width: 100%;
`;

interface ILayoutProps {
  children: NonNullable<ReactNode>;
}

export const Layout = ({ children }: ILayoutProps) => {
  return (
    <OuterContainer>
      <Header />
      <Toolbar />
      <InnerContainer>
        <Drawer />
        <div style={{ width: '100%' }}>
          <Main>{children}</Main>
        </div>
      </InnerContainer>
    </OuterContainer>
  );
};
