import { TeamDetails } from "../types/TeamDetails";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Config from "../config";
import { createQueryParamString } from 'utilities/RequestUtil';
import {QueryParam} from '../types/QueryParam';
import axios, { AxiosError, AxiosResponse } from "axios";
import { TeamMember } from "types/TeamMember";
import { useNavigate } from "react-router-dom";
import Problem from "types/problem";
import { Team } from "types/Team";
import { TeamDetailLevel } from "constants/TeamDetailLevel";
const useFetchTeamNames = (user: number) => {
  const queryParams: QueryParam[] = [
    {
      name: 'ownerId',
      value: user
    },
    {
      name: 'detailLevel',
      value: TeamDetailLevel.Basic
    }  
  ];
  var queryString = createQueryParamString(queryParams)
  return useQuery<Team[], AxiosError>(["TeamNames", user], () =>
    axios
      .get(`${Config.baseApiUrl}/teams/${queryString}`)
      .then((resp) => resp.data),
      {
        enabled: user>0
        
      },
  );
};
const useFetchTeams = (user: number) => {
  const queryParams: QueryParam[] = [
    {
      name: 'ownerId',
      value: user
    },
    {
      name: 'detailLevel',
      value: TeamDetailLevel.Full
    }  
  ];
  var queryString = createQueryParamString(queryParams)
  return useQuery<TeamDetails[], AxiosError>(["Teams", user], () =>
    axios
      .get(`${Config.baseApiUrl}/teams/${queryString}`)
      .then((resp) => resp.data)
  );
};
const useFetchTeam = (id: number, includeOperations: boolean) => {  
  const queryParams: QueryParam[] = [
    {
      name: 'includeOperations',
      value: includeOperations
    }  
  ];
  var queryString = createQueryParamString(queryParams)
  return useQuery<TeamDetails, AxiosError>(["Team", id], () =>
    axios
      .get(`${Config.baseApiUrl}/teams/${id}/${queryString}`)
      .then((resp) => resp.data),
      {
        enabled: id>0
      },
  );
};
const useFetchTeamMember = (searchString: string) => {
  const queryParams: QueryParam[] = [
    {
      name: 'name',
      value: searchString
    }  
  ];
  var queryString = createQueryParamString(queryParams)
  return useQuery<TeamMember[], AxiosError>(["TeamMember", searchString], () =>
    axios.get(`${Config.baseApiUrl}/sapuser${queryString}`).then((resp) => resp.data),
    {
      enabled: searchString.length>2
      
    },
  );
};

const useAddTeam = () => {
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useMutation<AxiosResponse, AxiosError<Problem>, TeamDetails>(
    (h) => axios.post(`${Config.baseApiUrl}/Team`, h),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Teams");
        nav(`/teams`);
      },
    }
  );
};

const useUpdateTeam = () => {
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useMutation<AxiosResponse, AxiosError<Problem>, TeamDetails>(
    (h) => axios.put(`${Config.baseApiUrl}/Team`, h),
    {
      onSuccess: (_, team) => {      
        queryClient.invalidateQueries(["Team", team.id]); //Please make sure to use correct combination of queryKey to Invalidate the query.Here,I am using Name and Id as a querykey.
        queryClient.invalidateQueries("Teams");      
        nav(`/teams`);
      },
    }
  );
};

const useDeleteTeam = () => {
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useMutation<AxiosResponse, AxiosError<Problem>, number>(
    (id) => axios.delete(`${Config.baseApiUrl}/Teams/${id}`),
    {
      onSuccess: (_, id) => {
        queryClient.invalidateQueries(["Team", id]);
        queryClient.invalidateQueries("Teams");
        nav(`/teams`);
      },
    }
  );
};


export {  useFetchTeams,
          useFetchTeamNames,
          useFetchTeamMember,
          useFetchTeam,
          useAddTeam,
          useUpdateTeam,
          useDeleteTeam };
