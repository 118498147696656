import React from 'react';
import { Avatar, Grid } from "@mui/material";
import { useAtom } from "jotai";
import { Assignment } from "types/Assignment";
import { Operation } from "types/Operation";
import { OwnerIdAtom } from 'Atoms/AuthAtoms';
import { useUpdateOperationAssignedUsers } from "hooks/WorkAssignmentHooks";
import * as SystemAlertImages from '../../assets/SystemAlerts';
import CustomChip from "./CustomChip";
import { GridItemStyle } from "../WorkAssignmnetGrid/GridPanelContentStyles";
import OperationLongTextChip from "./OperationLongTextChip";
import { AlertDescriptions } from 'constants/AlertDescriptions';
import { AlertToolTip } from './AlertToolTip';
import { useTranslation } from "react-i18next";

type Args = {
  row: Operation;
};

export const GridPanelContent: React.FC<Args> = ({ row }) => {
  const [ownerId, ] = useAtom(OwnerIdAtom);
  const [t, ] = useTranslation();
  const updateMutation = useUpdateOperationAssignedUsers();

  const handleDeleteChip = (chipToDelete: Assignment) => {
    chipToDelete.deletedByOwnerId = ownerId;
    updateMutation.mutate(chipToDelete);
  };

  return (
    <div style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)', marginBottom: 5 }}>
      <Grid container spacing={0.7}>
        <Grid item sx={GridItemStyle} xs={6} md={3}>
           <OperationLongTextChip label={t("OperationLongTextLabelText")} value={row.operationLongText} />
        </Grid>
        <Grid item sx={GridItemStyle} xs={6} md={3}>
          <CustomChip onDeleteChip={handleDeleteChip} label={t("AssignToSyncToPLMText")} values={row.assignedUsers} />
        </Grid>
        <Grid item sx={GridItemStyle} xs={6} md={3}>
          <CustomChip label={t("ManuaalyDownloadedByText")} values={row.selfAssignedUsers.map((x, index) => { return { userName: x, id: index, teamId: 0, operationId: 0, sync: false, userId: 0, assign: false, createdByOwnerId: 0 } })} />
        </Grid>
        <Grid item sx={GridItemStyle} style={{ textAlign: 'right', marginRight: '-10px', display: 'flex', alignItems: 'center' }} xs={1.9} md={1.9}>
          {row.systemAlerts.map((alert, index) => {
            const imageUrl = SystemAlertImages[alert as keyof typeof SystemAlertImages];
            const alertDesc = AlertDescriptions[alert];
            return (
              <AlertToolTip key={alert + index} title={<div>{alertDesc.description}</div>}>
                <Avatar alt={alert} src={imageUrl} sx={{ width: 28, height: 28, display: 'inline-block' }} key={index} />
              </AlertToolTip>
            );
          })}
          {row.permitsCount > 0 && (
            <AlertToolTip title={t("PermitsText")}>
              <Avatar src={SystemAlertImages.Permits} alt={t("PermitsText")} sx={{ width: 28, height: 28, display: 'inline-block' }}>
              </Avatar>
            </AlertToolTip>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default GridPanelContent;
