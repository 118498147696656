import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

export const TableStyle: SxProps<Theme> = {
    
    overflow:'auto',
    width:'100%',   
    fontSize:'14px',
    fontWeight:600,
    marginBottom:2,
    '.MuiDataGrid-columnSeparator': {
        display: 'none',
    },
    '.MuiDataGrid-cellContent':
    {
    fontSize: '0.8em',
    textAlign:'center',
    fontWeight:400,
    zIndex:0,
    color:'black'
    },
    borderRadius:1,     
    '& .static-cell:hover': {
        cursor: 'default',
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: 'rgb(199, 199, 199,0.2)',
        fontSize: '0.8em',
        fontWeight:600,
        zIndex:0,
        textAlign:'center'
    },
}