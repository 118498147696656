import { filtersAtom, refreshGridDataAtom, workAssignmentDataAtom } from "Atoms/WorkAssignmentGridAtoms";
import axios, { AxiosError, AxiosResponse } from "axios";
import Config from "config";
import { useAtom } from "jotai";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { Assignments } from "types/Assignments";
import Problem from "types/problem";

const useAddAssignments = (teamId: number) => {
    const queryClient = useQueryClient();
    const [filters] = useAtom(filtersAtom);
    const [refreshGridCount, setRefreshGridCount] = useAtom(refreshGridDataAtom);
    const nav = useNavigate();
    return useMutation<AxiosResponse, AxiosError<Problem>, Assignments>(
      (h) => axios.post(`${Config.baseApiUrl}/assignment`, h),
      {
        onSuccess: () => {
          const count = refreshGridCount + 1;
          setRefreshGridCount(count)
          queryClient.invalidateQueries(["filters", filters]);
          queryClient.invalidateQueries(["Team", teamId]);
          nav(`/workassignment`);
        },
      }
    );
  };

export { useAddAssignments };