import React from 'react';
import { FormLabelTitle, WrapperDiv } from './OperationLongTextChipStyles';

interface OperationLongTextChipProps {
  label: string;
  value: string;
}

const OperationLongTextChip: React.FC<OperationLongTextChipProps> = ({ label, value }) => {
  return (
    <>
      <FormLabelTitle>{label}</FormLabelTitle>
      <WrapperDiv dangerouslySetInnerHTML={{ __html: value }}>       
      </WrapperDiv>
    </>
  );
};

export default OperationLongTextChip;
