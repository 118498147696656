import axios, { AxiosError } from "axios";
import Config from "config";
import { useQuery } from "react-query";
import { Configuration } from "types/Configuration";

const useFetchConfiguration = (key: string) => {
  return useQuery<Configuration, AxiosError>(["Configuration", key], () =>
    axios
      .get(`${Config.baseApiUrl}/configuration/${key}`)
      .then((resp) => resp.data),
      {
        enabled: key.length > 0
      },
  );
};

export { useFetchConfiguration };
