import ValidationSummary from "components/Common/ValidationSummary";
import { useAddTeam } from "hooks/TeamsHooks";
import TeamForm from "./TeamForm";

const CreateTeam = () => {
  const addTeamMutation = useAddTeam();
 
 const teamId = 0;

  return (
    <>
      {addTeamMutation.isError && (
        <ValidationSummary error={addTeamMutation.error} />
      )}
      <TeamForm
        teamId={teamId} 
        submitted={(team) => addTeamMutation.mutate(team)} />
    </>
  );
};

export default CreateTeam;
