import { AutoCompleteValues } from "types/AutoCompleteValues";

export const formatName = (name: string): string => {
    const formattedName = name
      .replace(/\s*\([^)]*\)/, "") // Remove any text inside parentheses
      .split(", ") // Split the name into an array of ["Last Name", "First Name"]
      .reverse() // Reverse the array to get ["First Name", "Last Name"]
      .join(" "); // Join the array back into a string with a space separator
    
    return formattedName;
  };
 export const getValueFromObject = (myObj: any, key: string): any => {
    return myObj[key];
  }
 export const getMatchingValues = (
    autoComplete: AutoCompleteValues[],
    settings: string[]
  ): AutoCompleteValues[] => {
    return autoComplete.filter((a) =>
      settings.includes(a.id)
    );
  };
  