import { SettingKey } from "constants/SettingKey";
import { Settings } from "types/Settings";

export const findAddedSettings = (
    existingSettings: Settings[],
    selectedValues: string[],
    settingKey: string,
    ownerUserId: number
  ): Settings[] => {
    const addedSettings: Settings[] = [];
    
    // Create a set of existing setting values
    const existingSettingsSet = new Set(existingSettings.map((setting) => setting.settingValue));
  
    // Iterate over selectedValues and add the ones not present in existingSettingsSet
    selectedValues.forEach((value) => {
      if (!existingSettingsSet.has(value)) {
        const addedSetting: Settings = {
          id: 0, // Assign a new ID here if needed
          settingKey: settingKey,
          settingValue: value,
          userId: ownerUserId,
        };
        addedSettings.push(addedSetting);
      }
    });
    
    return addedSettings;
  };
  

export const findRemovedSettings = (existingSettings: Settings[], selectedValues: string[]): Settings[] =>
  existingSettings.filter(existingSetting => !selectedValues.includes(existingSetting.settingValue));
