import { Settings } from "../types/Settings";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Config from "../config";
import axios, { AxiosError, AxiosResponse } from "axios";
import { QueryParam } from "types/QueryParam";
import { createQueryParamString } from "utilities/RequestUtil";
import Problem from "types/problem";

const useFetchsettings = (user: number, settingKey: string) => {
  const queryParams: QueryParam[] = [
    {
      name: 'userId',
      value: user
    },
    {
      name: 'settingKey',
      value: settingKey
    }  
  ];
  var queryString = createQueryParamString(queryParams);

  const query = useQuery<Settings[], AxiosError>(["UserSettings", user, settingKey], () =>
    axios
      .get(`${Config.baseApiUrl}/usersettings/${queryString}`)
      .then((resp) => {       
        return resp.data;
      })
  );

  return query;
};

const useUpdateSettings = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError<Problem>, Settings>(
    (h) => axios.put(`${Config.baseApiUrl}/UserSettings`, h),
    {
      onSuccess: (_, UserSettings) => {
        const user = UserSettings.userId;
        const settingKey = UserSettings.settingKey;

        queryClient.invalidateQueries(["UserSettings", user, settingKey]);
      },
    }
  );
};

const useInsertSettings = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError<Problem>, Settings[]>(
    (h) => axios.post(`${Config.baseApiUrl}/UserSettings`, h),
    {
      onSuccess: (_, UserSettings) => {
         queryClient.invalidateQueries("UserSettings");
      },
    }
  );
};

const useDeleteUserSettings = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError<Problem>, Settings[]>(
    (userSettingsArray) => {    
      const ids = userSettingsArray.map((userSettings) => userSettings.id).join(',');
      return axios.delete(`${Config.baseApiUrl}/UserSettings/${ids}`);
    },
    {
      onSuccess: (_, userSettingsArray) => {
        const invalidatedQueries = userSettingsArray.map((userSettings) => {
          const { userId, settingKey } = userSettings;
          return ["UserSettings", userId, settingKey];
        });
        queryClient.invalidateQueries(invalidatedQueries);
      },
    }
  );
};

export {  useFetchsettings,
          useUpdateSettings,
          useInsertSettings,
          useDeleteUserSettings,
         };



